// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300);
@import url(https://fonts.googleapis.com/css?family=Lusitana:400,700);

// Helpers
@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

.align-center {
  text-align: center;
}

// Grid
.row {
  @include clearfix;
  .col {
    padding: 0 20px;
    float: left;
    box-sizing: border-box;
    &.x-50 {
      width: 50%;
    }
    &.x-100 {
      width: 100%;
    }
  }
}

.content-wrapper {
  min-height: 100%;
  position: relative;
}

.get-in-touch {
  .title {
    text-align: center;
    font-family: Raleway, sans-serif;
    text-transform: uppercase;
    letter-spacing: 3px;
    font-size: 36px;
    line-height: 48px;
    padding-bottom: 48px;
  }
}

.contact-form {
  .form-field {
    position: relative;
    margin: 32px 0;
  }
  .input-text {
    display: block;
    width: 100%;
    height: 36px;
    border-width: 0 0 2px 0;
    border-color: #000;
    font-family: Lusitana, serif;
    font-size: 18px;
    line-height: 26px;
    font-weight: 400;
    @media (prefers-color-scheme: dark) {
      background: $dark-background;
      color: $dark-color;
    }

    @media (prefers-color-scheme: light) {
      background: $light-background;
      color: $light-color;
    }

    [data-theme=dark] & {
      background: $dark-background;
      color: $dark-color;
    }

    [data-theme=light] & {
      background: $light-background;
      color: $light-color;
    }

    &:focus {
      outline: none;
    }

    &:focus,
    &.not-empty {
      + .label {
        transform: translateY(-24px);
      }
    }
    &:not(:placeholder-shown) {
      + .label {
        transform: translateY(-24px);
      }
    }
  }
  .label {
    position: absolute;
    left: 20px;
    bottom: 11px;
    font-family: Lusitana, serif;
    font-size: 18px;
    line-height: 26px;
    font-weight: 400;
    color: #888;
    cursor: text;
    transition: transform .2s ease-in-out;
  }

  .submit-btn {
    display: inline-block;
    background-color: #000;
    color: #fff;
    font-family: Raleway, sans-serif;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 16px;
    line-height: 24px;
    padding: 8px 16px;
    border: none;
    cursor: pointer;
  }
}

.note {
  position: absolute;
  left: 0;
  bottom: 10px;
  width: 100%;
  text-align: center;
  font-family: Lusitana, serif;
  font-size: 16px;
  line-height: 21px;

  .link {
    color: #888;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}
